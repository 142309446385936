import './App.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    alignItems: 'stretch',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    minWidth: '6rem',
    color: 'white',
  },
  title: {
    marginLeft: '7rem',
    textAlign: 'center',
    flexGrow: 1,
    color: '#fff',
    fontFamily: "Georgia, 'Times New Roman', Times, serif",
    fontSize: '2rem',
    fontWeight: 'bold',
  },
}));


export default function ConnectAppBar(props) {
  const classes = useStyles();
  console.log({ props });

  return (
    <div className={classes.root}> 
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
          ● miran ●
          </Typography>
          {(props.user ?  
            <Button variant="contained" color="primary" className={classes.menuButton} onClick={props.logOutAction}>Sign Out</Button>
          : '')}
        </Toolbar>
      </AppBar>
    </div>
  );
}