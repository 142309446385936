import React from 'react';
import ReactDOM from 'react-dom';
import AWSAppSyncClient from 'aws-appsync'
import { ApolloProvider } from 'react-apollo'
import Amplify, { Auth } from 'aws-amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppSyncConfig from './aws-exports'; 
import ConnectAppBar from './ConnectAppBar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#02a378',
    },
    secondary: {
      main: '#7802a3',
    },
  },
});

Amplify.configure(AppSyncConfig);

const client = new AWSAppSyncClient({
  url: AppSyncConfig.aws_appsync_graphqlEndpoint,
  region: AppSyncConfig.aws_appsync_region,
  auth: {
    type: AppSyncConfig.aws_appsyn6c_authenticationType,
  }
});

let userCheckInterval = null;


const userCheck = async () => {
  const authUser = await Auth.currentUserInfo();
  if (authUser) {
    await Auth.currentSession().then(
      session => {
        const accessToken = session.getAccessToken();
        clearInterval(userCheckInterval);
        console.log({ authUser, accessToken });
        render(authUser, session);
        //document.getElementById('auth').submit();
      }
    );
  }
}

function render(user, session) {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
            <ConnectAppBar user={user} logOutAction={() => {
              Auth.signOut();
              document.getElementById("jwt").value = '';
              document.getElementById("auth").submit();
            }} />
            {user ? <App user={user} session={session} />
            : 
            <div>
              {'' //no user
              }
              <App user={user} session={session} />
            </div> 
            }
        </ApolloProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

render(null, null);
userCheckInterval = setInterval(userCheck, 1000);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
