import './App.css';
import React from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import './cognito.css';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Divider, Paper, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '8px',
  },
  paper: {
    paddingTop: '16px',
  },
}));


function App(props) {
  const [state, setState] = React.useState({
    checkedAgreement: false,
  });

  const classes = useStyles();
  if (!props.user) {
    return '';
  }
  const { user, session } = props;
  const accessToken = session.getAccessToken()
  const groups = accessToken.payload['cognito:groups'];

  if (groups && groups.includes('AlphaUsers')) {
    const handleChange = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
      <Container className="App">
          <p>Welcome back, {props.user.username}!</p>
          <form id="auth" method="post" action="https://play.miran.app/authorize">
          <input type="hidden" id="jwt" name="jwt" value="" />
          </form>
          <Paper className={classes.paper}>
          <FormControl className={classes.formControl} component="fieldset">
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <Typography className={classes.heading}>User Agreement Terms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
                User agress to etc...
              </Typography>
            </AccordionDetails>
          </Accordion>
          </FormControl>
          <FormControl className={classes.formControl} component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox name="checkedAgreement" checked={state.checkedAgreement} color="primary" onChange={handleChange} />}
                label="I have read and agree to the above terms"
                labelPlacement="end"
              />
              <Button disabled={!state.checkedAgreement} 
                onClick={() => {
                  const jwtToken = accessToken.getJwtToken();
                  document.getElementById("jwt").value = jwtToken;
                  document.getElementById("auth").submit();
                }}
                color="secondary" size="large" variant="contained">Go to app</Button>
            </FormGroup>
          </FormControl>
          </Paper>
          <form id="auth" method="post" action="https://play.miran.app/authorize">
          <input type="hidden" id="jwt" name="jwt" value="" />
          </form>
      </Container>
    );
  }
  return (
    <div className="App">
      <header className="App-header">
        <p>Welcome, {props.user.username}!</p>
        <p>Thank you for signing up and showing your interest in miran.app</p>
        <p>We're currently still in testing, but we'll be sure to keep you updated with news in the meantime.</p>
        <p>Also before opening the app to the public, we are planning to allow some users for early access and feedback. If you are interested in participating please let us know here: </p>
        <p>
          [todo: form]
        </p>
        
      </header>
    </div>
  );
}

export default withAuthenticator(App, {
  signUpConfig: {
    hiddenDefaults: ['phone_number'],
  }
});
 